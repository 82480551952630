import { Box, Button, Center, Icon, ModalProps } from '@chakra-ui/react';
import { BigNumber, BigNumberish } from 'ethers';
import { useCallback, useMemo, useState } from 'react';
import { FaArrowDown } from 'react-icons/fa';
import SimplePopup from '../components/SimplePopup';
import useRefillHoneyJar from '../hooks/useRefillHoneyJar';
import useRefilling from '../hooks/useRefilling';
import useRefillPredefinedValues from '../hooks/useRefillPredefinedValues';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';
import { formatEtherBalance } from '../utils/numberUtils';
import { calculateETHToHoney, calculateHoneyToETH } from '../utils/ratesUtils';
import EthIcon from './EthIcon';
import HoneyIcon from './HoneyIcon';
import RefillAmountInput from './RefillAmountInput';

type RefillPopupProps = Omit<ModalProps, 'children'> & {
  tokenId: number;
  onSuccess: () => void;
};

const RefillPopup = ({ tokenId, onSuccess, ...props }: RefillPopupProps) => {
  const translate = useTranslate();

  const { balance } = useWalletAssets();
  const { honeyInJars } = useWalletAssets();

  const honeyInJar = honeyInJars?.[tokenId];

  const { pricePerHoney, maxAllowedHoneyAmountInHoneyJar } = useRefilling();

  const [honeyAmount, setHoneyAmount] = useState<BigNumberish>('0');
  const [ethAmount, setEthAmount] = useState<BigNumberish>('0');

  const [refillHoneyJar, isRefilling] = useRefillHoneyJar(
    tokenId,
    honeyAmount,
    ethAmount,
    onSuccess
  );

  const maxHoneyAmount = useMemo(() => {
    if (maxAllowedHoneyAmountInHoneyJar) {
      return honeyInJar
        ? maxAllowedHoneyAmountInHoneyJar.sub(honeyInJar)
        : maxAllowedHoneyAmountInHoneyJar;
    }
  }, [honeyInJar, maxAllowedHoneyAmountInHoneyJar]);

  const maxEthAmount = useMemo(() => {
    if (maxHoneyAmount && pricePerHoney) {
      return calculateHoneyToETH(maxHoneyAmount, pricePerHoney);
    }
  }, [maxHoneyAmount, pricePerHoney]);

  const predefindeValues = useRefillPredefinedValues(maxHoneyAmount);

  const handleHoneyAmountChange = useCallback(
    (value: BigNumberish) => {
      if (pricePerHoney && maxHoneyAmount) {
        if (BigNumber.from(value).gt(maxHoneyAmount)) {
          setHoneyAmount(maxHoneyAmount);
          setEthAmount(calculateHoneyToETH(maxHoneyAmount, pricePerHoney));
        } else {
          setHoneyAmount(value);
          setEthAmount(calculateHoneyToETH(value, pricePerHoney));
        }
      }
    },
    [pricePerHoney, maxHoneyAmount]
  );

  const handleEthAmountChange = useCallback(
    (value: BigNumberish) => {
      if (pricePerHoney && maxHoneyAmount) {
        const honeyAmount = calculateETHToHoney(value, pricePerHoney);

        if (honeyAmount.gt(maxHoneyAmount)) {
          setHoneyAmount(maxHoneyAmount);
          setEthAmount(calculateHoneyToETH(maxHoneyAmount, pricePerHoney));
        } else {
          setEthAmount(value);
          setHoneyAmount(calculateETHToHoney(value, pricePerHoney));
        }
      }
    },
    [pricePerHoney, maxHoneyAmount]
  );

  const handleRefill = useCallback(() => {
    refillHoneyJar?.();
  }, [refillHoneyJar]);

  return (
    <SimplePopup
      size="md"
      title={translate('refill:title', { tokenId })}
      description={translate('refill:description', {
        ethAmount: maxEthAmount ? formatEtherBalance(maxEthAmount) : '0',
        honeyAmount: maxHoneyAmount ? formatEtherBalance(maxHoneyAmount) : '0',
      })}
      {...props}
    >
      <Box mt="5" mb="6">
        <Box w="full">
          <RefillAmountInput
            icon={<Icon as={HoneyIcon} w="5" h="5" color="primary.500" />}
            placeholder="Eg. 71000"
            badge="$HONEY"
            badgeW="90px"
            value={honeyAmount}
            max={maxHoneyAmount}
            predefinedValues={predefindeValues}
            isDisabled={isRefilling}
            onChange={handleHoneyAmountChange}
          />

          <Center
            w="12"
            h="12"
            bg="dark.900"
            borderRadius="full"
            position="relative"
            zIndex={2}
            mx="auto"
            my="-3"
            flex="none"
          >
            <Icon as={FaArrowDown} color="primary.500" w="4" h="4" />
          </Center>

          <RefillAmountInput
            icon={<Icon as={EthIcon} w="5" h="5" color="primary.500" />}
            placeholder="Eg. 0.01"
            badge="ETH"
            badgeW="60px"
            max={maxEthAmount}
            value={ethAmount}
            isDisabled={isRefilling}
            onChange={handleEthAmountChange}
          />
        </Box>
      </Box>

      <Button
        size="lg"
        w="full"
        fontSize="lg"
        isDisabled={balance?.gte(ethAmount) ? false : true}
        onClick={handleRefill}
        isLoading={isRefilling}
      >
        {translate('refill:submit')}
      </Button>
    </SimplePopup>
  );
};

export default RefillPopup;
